<template>
  <div>
    <div
      class="
        d-flex
        justify-content-md-between
        flex-column flex-md-row
        align-items-center
        pb-5
      "
    >
      <div class="d-flex flex-column mt-1">
        <CommonEmployeeSummary
          :item="setEmployeeComponent()"
        ></CommonEmployeeSummary>
      </div>
      <AppFieldDateSelector @clickedSearch="filterDate"> </AppFieldDateSelector>
    </div>
    <b-table
      ref="personal-monthly-table"
      id="personal-monthly-table"
      table-class="table-head-custom table-vertical-center mt-5"
      :responsive="true"
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      hover
      @row-clicked="showClockRecord"
    >
      <!-- begin:header template    -->
      <template v-slot:head(date)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end:header template    -->

      <!-- begin: cell templates      -->
      <template v-slot:cell(date)="data">
        <div class="d-flex align-items-center">
          <div class="ml-4">
            <div
              class="
                btn btn-light-primary btn-sm
                font-weight-bold
                btn-upper btn-text
              "
            >
              {{ transformDate(data.item.date) }}
            </div>
            <span class="ml-3 font-weight-bold">{{
              getDay(data.item.date)
            }}</span>
          </div>
        </div>
      </template>

      <template v-slot:cell(status)="data">
        <span>{{ data.item.status.name }}</span>
      </template>

      <template v-slot:cell(attendance.time_in)="data">
        <span class="font-weight-bold" v-if="data.item.attendance">
          <span
            class="menu-item bg-light-danger rounded px-2 py-2 text-danger"
            v-if="data.item.attendance.late"
            v-b-tooltip.hover="'Late'"
          >
            {{ data.item.attendance.time_in }}
          </span>
          <span class="" v-if="!data.item.attendance.late">
            {{ data.item.attendance.time_in }}
          </span>
        </span>
      </template>

      <template v-slot:cell(attendance.temperature)="data">
        <span
          class="font-weight-bolder"
          v-bind:class="{
            'text-success': data.item.attendance.temperature.is_safe,
            'text-danger': !data.item.attendance.temperature.is_safe,
          }"
          v-if="data.item.attendance && data.item.attendance.temperature"
        >
          {{ data.item.attendance.temperature.temp + "\xB0C" }}
        </span>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-right">
          <!-- begin: Device Users Modal Button         -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showClockRecord(data.item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
              <inline-svg src="/media/svg/icons/Home/Alarm-clock.svg" />
            </span>
          </button>
        </div>
      </template>
      <template #table-busy>
        <AppLoader></AppLoader>
      </template>
    </b-table>

    <EmployeePageClockRecordModal
      v-bind:attendance-id="attendanceId"
    ></EmployeePageClockRecordModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/core/plugins/vue-i18n";
import dayjs from "dayjs";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import AppFieldDateSelector from "@/modules/core/components/utility/date/AppFieldDateSelector";
import EmployeePageClockRecordModal from "@/modules/company/components/employees/EmployeePageClockRecordModal";
export default {
  name: "PersonalAttendanceMonthlyPageTable",
  components: {
    AppFieldDateSelector,
    AppLoader,
    CommonEmployeeSummary,
    EmployeePageClockRecordModal,
  },
  data() {
    return {
      attendanceId: null,
      fields: [
        { key: "date", label: i18n.t("ATTENDANCE.DATE") },
        { key: "attendance.time_in", label: i18n.t("ATTENDANCE.IN") },
        { key: "attendance.time_out", label: i18n.t("ATTENDANCE.OUT") },
        {
          key: "attendance.temperature",
          label: i18n.t("ATTENDANCE.TEMPERATURE"),
        },
        { key: "status", label: "Status" },
        { key: "actions", label: i18n.t("ATTENDANCE.ACTIONS") },
      ],
      isBusy: false,
    };
  },
  methods: {
    setEmployeeComponent() {
      return {
        name: this.employee.first_name + " " + this.employee.last_name,
        code: this.employee.unique_code,
        email: this.employee.email,
        image_url: this.employee.image_url,
      };
    },
    date() {
      return new Date();
    },
    transformDate(date) {
      const t = new Date(date);
      // https://day.js.org/docs/en/display/format
      return dayjs(t).format("DD/MM");
    },
    getDay(date) {
      const t = new Date(date);
      // https://day.js.org/docs/en/display/format
      return dayjs(t).format("dddd");
    },

    filterDate(data) {
      console.log(data);

      const date = dayjs({
        year: data.year,
        month: data.month,
      });

      this.$store.commit(
        "setEmployeePersonalAttendanceMonthlyDateFilter",
        date.format()
      );

      this.fetch();
    },

    fetchEmployee() {
      return this.$store
        .dispatch("getEmployeePersonalProfile")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        })
        .catch(() => {});
    },

    fetchAttendance() {
      return this.$store
        .dispatch("fetchEmployeePersonalAttendanceMonthly")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        });
    },

    fetch() {
      this.isBusy = true;
      this.fetchEmployee();
      this.fetchAttendance().finally(() => (this.isBusy = false));
    },

    showClockRecord(item) {
      console.log(item);
      this.attendanceId = item.attendance ? item.attendance.id : null;
      this.$bvModal.show("employee-page-clock-record-modal");
    },
  },
  mounted() {
    this.fetch();
  },
  computed: {
    ...mapGetters({
      employee: "getEmployeePersonalProfile",
      items: "getEmployeePersonalAttendanceMonthly",
    }),
  },
};
</script>

<style scoped></style>
