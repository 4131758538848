<template>
  <div class="d-flex mx-3">
    <b-form-group label="Year" class="mr-3">
      <b-form-select
        v-model="yearSelection"
        :options="years"
        @input="emitSelected"
        class="form-control-solid"
      >
      </b-form-select>
    </b-form-group>
    <b-form-group label="Month">
      <b-form-select
        v-model="monthSelection"
        :options="months"
        @input="emitSelected"
        class="form-control-solid"
      >
      </b-form-select>
    </b-form-group>
    <div class="my-auto ml-3 mr-auto" v-if="!noSearch">
      <div
        class="btn btn-icon btn-light btn-hover-primary mr-3"
        @click="searchByMonth"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <inline-svg src="/media/svg/icons/General/Search.svg" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "AppFieldDateSelector",
  props: {
    monthField: {
      type: Number,
      default: dayjs().month(),
    },
    yearField: {
      type: Number,
      default: dayjs().year(),
    },
    noSearch: {
      type: Boolean,
      default: false,
    },
    dateValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      monthSelection: this.monthField,
      yearSelection: this.yearField,
    };
  },
  methods: {
    /**
     * Search button clicked event
     */
    searchByMonth() {
      this.defineDateFilter();
      this.$emit("clickedSearch", {
        year: this.yearSelection,
        month: this.monthSelection,
      });
    },

    /**
     * Emit changed date
     */
    emitSelected() {
      this.defineDateFilter();
      this.$emit("select-date", {
        year: this.yearSelection,
        month: this.monthSelection,
      });
    },

    /**
     * Set the date filter
     */
    defineDateFilter() {
      const objectSupport = require("dayjs/plugin/objectSupport");
      dayjs.extend(objectSupport);

      const searchable = dayjs({
        year: this.yearSelection,
        month: this.monthSelection,
      });
      console.log(searchable.format());
    },
  },
  watch: {
    dateValue(newVal) {
      console.log(newVal);

      this.defineDateFilter();
      this.$emit("fetched-date", {
        year: this.yearSelection,
        month: this.monthSelection,
      });
    },
  },
  computed: {
    /**
     * Compute year
     *
     * @returns {*[]}
     */
    years() {
      const now = dayjs();
      let years = [];
      const max = 5;

      for (let i = 0; i < max + 1; i++) {
        let sYear = now.subtract(1 + max - i, "year");
        years.push({
          value: sYear.format("YYYY"),
          text: sYear.format("YYYY"),
        });
      }

      for (let i = 0; i < max; i++) {
        let sYear = now.add(i, "year");
        years.push({
          value: sYear.format("YYYY"),
          text: sYear.format("YYYY"),
        });
      }

      return years;
    },

    /**
     * Compute list of months
     *
     * @returns {*}
     */
    months() {
      require("dayjs/locale/en");
      require("dayjs/locale/ms");
      const localeData = require("dayjs/plugin/localeData");
      dayjs.extend(localeData);
      dayjs.locale(this.$i18n.locale);

      console.log(dayjs.months(), this.$i18n.locale);

      const months = dayjs.months().map((month, key) => {
        return {
          text: month,
          value: key,
        };
      });
      console.log(months);
      return months;
    },
  },
};
</script>

<style scoped></style>
